import { Sc01, Sc02 } from '../screens';
import { AllPrivate, AllPublic } from './routeGroup';

export default {
  path: '/',
  name: 'All App',
  // prettier-ignore
  childRoutes: [
    { path: '/home', name: 'Home', component: Sc01, isIndex: true },
    { path: '', name: 'All Private', component: AllPrivate, childRoutes: [
        { path: '/adm', name: 'Adm', component: Sc02 },
      ]
    }
  ]
};
