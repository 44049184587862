import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';

export default class PageNotFound extends PureComponent {
  render() {
    return (
      <View>
        <Text>Page not found.</Text>
      </View>
    );
  }
}
