import firebase from '../platforms/firebase';

// Pass your firebase key
const prodConfig = {
  apiKey: "AIzaSyCSw47Q5b4Ho2HzW_t2ahFbtkBj9foWDQQ",
  authDomain: "ser-girafa.firebaseapp.com",
  databaseURL: "https://ser-girafa.firebaseio.com",
  projectId: "ser-girafa",
  storageBucket: "ser-girafa.appspot.com",
  messagingSenderId: "280315490701",
  appId: "1:280315490701:web:8614de53908f5d8ab9cdc4",

  persistence: true
};

/*
    Pass the firebase key below only when you have
    2 environments = Production and Development.
  */

// const devConfig = {
// apiKey: "",
// authDomain: "",
// databaseURL: "",
// projectId: "",
// storageBucket: "",
// messagingSenderId: ""
// };

const devConfig = prodConfig;

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

/*
  Reed more about these 2 environments:
  https://blog.tecsinapse.com.br/criando-uma-aplica%C3%A7%C3%A3o-react-firebase-passo-a-passo-9ebc5a8a442f
  */

export const fbConfig = !firebase.apps.length && firebase.initializeApp(config);

//const settings = { timestampsInSnapshots: true };
//firebase.firestore().settings(settings);

// firebase.firestore().enablePersistence();
// export default fbConfig;

export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
