// This index is only to web
// To the mobile one see ../index

// import Packages
import React from 'react';
import { AppRegistry } from 'react-native';
// import internals
import { name as appName } from './app.json';
// import Root from './App';
import Root from './Root';
import registerServiceWorker from './registerServiceWorker';

// Register APP to react-native-web
AppRegistry.registerComponent(appName, () => Root);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
  registerServiceWorker
});
