import styled from 'styled-components';
import src01 from '../images/banner-top.jpg';
import src02 from '../images/benefits.jpg';
import src03 from '../images/curve-bg.png';
import src04 from '../images/phone.png';
import src05 from '../images/ornament.jpg';
import src06 from '../images/girafe.jpg';

// export const Navbar = styled.nav`
//   @media (max-width: 768px) {
//     color: #00ff00;
//   }
// `;

export const AllTxt = styled.div`
  font-size: 14px;
  font-family: arial;
  color: #999;
  p {
    font-size: 14px;
  }
`;

export const Menu = styled(AllTxt)`
  background: rgba(34, 34, 74, 1);
  box-sizing: border-box;
  color: #fff;
  padding: 22px 35px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  .title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .list {
    display: flex;
    flex-flow: column;
    margin-top: 20px;
    a {
      color: #fff;
      padding: 20px 0;
      text-decoration: none;
      border-bottom: 1px solid #3c3c61;
    }
  }
`;

export const GeneralLayout = styled.div`
  .links {
    padding: 40px 0;
  }
  .links a {
    cursor: pointer;
    margin-right: 5px;
  }
  .txLogo {
    font-family: arboria, sans-serif;
    font-size: 18px;
    font-weight: 100;
  }
  button {
    background: transparent;
    border: none;
  }
  .logo {
    width: 30px;
    height: 30px;
  }
  .row {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  .row-top {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    p {
      margin-top: 0;
      text-align: left;
    }
  }
  .ico {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .btn-download {
    padding: 8px 16px;
    border-radius: 5px;

    background: rgba(1, 131, 129, 1);
    background: -moz-linear-gradient(
      -45deg,
      rgba(1, 131, 129, 1) 0%,
      rgba(1, 92, 106, 1) 61%
    );
    background: -webkit-gradient(
      left top,
      right bottom,
      color-stop(0%, rgba(1, 131, 129, 1)),
      color-stop(61%, rgba(1, 92, 106, 1))
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba(1, 131, 129, 1) 0%,
      rgba(1, 92, 106, 1) 61%
    );
    background: -o-linear-gradient(
      -45deg,
      rgba(1, 131, 129, 1) 0%,
      rgba(1, 92, 106, 1) 61%
    );
    background: -ms-linear-gradient(
      -45deg,
      rgba(1, 131, 129, 1) 0%,
      rgba(1, 92, 106, 1) 61%
    );
    background: linear-gradient(
      135deg,
      rgba(1, 131, 129, 1) 0%,
      rgba(1, 92, 106, 1) 61%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#018381', endColorstr='#015c6a', GradientType=1 );
  }
  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }
  .flex3 {
    flex: 3;
  }
  .flexBetween {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
  .flexAround {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
  }
  .flexStart {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
  }
  .flexEnd {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
  }
  .links.mob {
    padding: 0 70px;
    margin-top: 53px;
  }
  .titleBanner {
    font-family: arboria, sans-serif;
    font-weight: 100;
  }
  .mob {
    display: none;
  }
  .desk {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 900px) {
    .banner .comp.bg {
      width: 311px;
      height: auto;
      right: -40px;
      bottom: -1px;
    }
    .banner .comp.cellphone {
      width: 520px;
      height: auto;
      right: -129px;
      bottom: -69px;
    }
    .benefits .txt {
      width: 65%;
    }
    .bnf-img {
      width: 434px;
      right: -100px;
      position: absolute;
    }
    .download div.txt {
      width: 100%;
    }
    .links {
      text-align: center;
    }
  }

  @media (max-width: 575.98px) {
    h1 {
      font-size: 20px !important;
    }
    h2 {
      font-size: 16px !important;
    }
    h4 {
      font-size: 14px !important;
    }
    p {
      font-size: 11px !important;
    }
    .title {
      h2 {
        max-width: 270px !important;
      }
    }
    .banner {
      flex-flow: column;
      padding: 0 40px;
      div {
        width: 100%;
        padding: 0 !important;
        justify-content: flex-start;
      }
      .layer {
        position: absolute;
      }
    }
    .comp.bg {
      display: none;
    }
    .comp.cellphone {
      width: 440px !important;
      bottom: -80px !important;
    }
    .navbar {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .about,
    .benefits,
    .download {
      flex-flow: column !important;
      background-size: 90%;
      padding: 0;
      width: initial;
      height: inherit !important;
      margin: 0 !important;
      div {
        width: initial !important;
      }
      .flex3 {
        margin: 20px 0;
      }
    }

    #footer {
      flex-flow: column !important;
      background-size: 90%;
      padding: 0;
      width: initial;
      height: inherit !important;
      margin: 0 !important;
      .flex3 {
        margin: 20px 0 45px 0;
      }
    }

    .bnf-img {
      width: 90%;
      right: 0;
      bottom: -218px;
      position: absolute;
      z-index: 0;
    }
    .download {
      margin-top: 260px !important;
    }
    .about {
      div {
        padding: 0 70px;
        margin-bottom: 30px;
      }
    }
    .foot {
      width: initial !important;
      padding: 60px 35px;
    }
    .wdt {
      width: 100%;
    }
    .scl-mtmob {
      flex-flow: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100% !important;
      .mkp {
        margin: 0;
      }
    }
    .designBy {
      font-size: 12px;
      margin-top: 20px !important;
    }
    .links {
      text-align: center;
    }
  }
  @media (min-width: 769px) {
    .desk {
      display: block;
    }
  }
  @media (max-width: 768px) {
    .mob {
      display: block;
    }
    .benefits {
      background-image: none !important;
      margin-top: 50px !important;
    }
    .download {
      background-image: initial !important;
      justify-content: flex-start !important;
    }
    #footer {
      flex-flow: column !important;
      background-size: 90%;
      padding: 0;
      width: initial;
      height: inherit !important;
      margin: 0 !important;
      .flex3 {
        margin: 20px 0 45px 0;
      }
    }
  }
`;

// background-color: rgba(7, 81, 101, 1);
export const TopSection = styled(AllTxt)`
  background: url(${src01});
  background-size: cover;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  padding: 0;
  .navbar {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px;
  }
  .banner {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .flex1 {
    display: flex;
    flex-flow: column;
    width: 50%;
    min-height: 460px;
    justify-content: center;
    position: relative;
    padding-left: 80px;
  }
  .comp {
    width: 570px;
    height: auto;
    position: absolute;
    &.bg {
      width: 380px;
      height: auto;
      right: 0;
      bottom: -1px;
    }
    &.cellphone {
      width: 550px;
      height: auto;
      right: 0px;
      bottom: -50px;
    }
  }
  a {
    color: #fff;
    margin-right: 20px;
    text-decoration: none;
  }
  a:hover {
    color: #00ff66;
  }
  span {
    margin-left: 5px;
  }
`;

export const Section = styled(AllTxt)`
  padding: 0px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-position-y: center;
  background-repeat: no-repeat;
  span {
    color: #ccc;
    font-size: 11px;
  }
  h2,
  h3,
  h4 {
    margin-top: 0;
    color: #333;
  }
  div.txt {
    width: 40%;
    z-index: 2;
  }
  &.about,
  &.benefits,
  &.download {
    min-height: 500px;
    box-sizing: border-box;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 50px;
  }
  &.title {
    flex-flow: column;
    justify-content: center;
    min-height: 220px;
    h2 {
      max-width: 480px;
      text-align: center;
    }
  }
  &.about {
    background-image: url(${src05});
    background-position-x: left;
    padding-left: 0px;
    padding-right: 0px;
    height: 400px;
    display: flex;
    justify-content: space-around;
    h4 {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 0;
    }
    div {
      width: 18%;
      text-align: center;
    }
  }
  &.benefits {
    background-image: url(${src02});
    background-position-x: right;
    position: relative;
  }
  &.download {
    background-image: url(${src06});
    background-position-x: left;
    background-position-y: -110px;
    justify-content: flex-end;
    padding-bottom: 50px;
    height: 650px;
  }
`;

export const Gradientfoot = styled(AllTxt)`
  background: rgba(7, 81, 101, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(7, 81, 101, 1) 0%,
    rgba(18, 3, 52, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(7, 81, 101, 1)),
    color-stop(100%, rgba(18, 3, 52, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(7, 81, 101, 1) 0%,
    rgba(18, 3, 52, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(7, 81, 101, 1) 0%,
    rgba(18, 3, 52, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(7, 81, 101, 1) 0%,
    rgba(18, 3, 52, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(7, 81, 101, 1) 0%,
    rgba(18, 3, 52, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#075165', endColorstr='#120334', GradientType=1 );
  max-width: 1200px;
  min-height: 280px;
  margin: 0 auto;
  color: #999;
  padding: 70px 100px;
  box-sizing: border-box;
  position: relative;
  div#footer {
    align-items: flex-start;
  }
  .detail {
    position: absolute;
    top: -190px;
    right: 0;
  }
  .mgLog {
    margin-left: 10px;
    font-size: 18px;
    color: #eee;
    font-family: arboria, sans-serif;
    font-weight: 100;
  }
  .wdt {
    width: 70%;
  }
  .tuk {
    font-weight: bold;
  }
  .mkp {
    margin-top: 10px;
  }
  .designBy {
    border-top: 1px solid #999;
    padding-top: 20px;
    margin-top: 50px;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    cursor: hand;
  }
  .design {
    width: 70px;
    margin-left: 10px;
  }
`;
