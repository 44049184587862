// #region [setImports]
// ------------------------------

// import Packages
import React from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';

// import Internal Components
import {
  UseLink,
  useStyle,
  useReducer,
  useToggle,
  UseIcoMoon
} from './useMorfos';
import {
  GeneralLayout,
  Menu,
  TopSection,
  Section,
  Gradientfoot
} from './styledCss';
import src01 from '../images/logo.png';
import src02 from '../images/curve-bg.png';
import src03 from '../images/phone.png';
import src04 from '../images/play.png';
import src05 from '../images/pwa.png';
import src06 from '../images/logo-white.png';
import src07 from '../images/detail.png';
import src08 from '../images/benefits.jpg';
import src09 from '../images/morfos-logo.png';

const { stlColor1, stlBgColor1 } = useStyle;

// ------------------------------
// #endregion

export default function Sc00() {
  // #region [setLogic] hooks
  // ------------------------------

  // set hooks
  const { rdAuthUser, rdContent, rdMenuSelect } = useReducer();
  const screenContent = rdContent[0];
  // const callChangeRd = useChangeRd();

  const [sttMsg, toggleMsg] = useToggle(false);

  // ------------------------------
  // #endregion

  const _STYLE = props => {
    // #region [NOsetLogic]
    // ------------------------------

    // ------------------------------
    // #endregion

    // #region [allStyles]
    // ------------------------------

    let stlView01 = [useStyle.flexMaster];
    let stlScroll01 = [useStyle.scrollView];

    // ------------------------------
    // #endregion

    return (
      // #region [component]
      // ------------------------------

      <View className="scroll" style={stlView01}>
        <MENU />
        <ScrollView style={stlScroll01}>
          <GeneralLayout>
            {/*  */}

            {props.children}

            {/*  */}
          </GeneralLayout>
        </ScrollView>
      </View>

      // ------------------------------
      // #endregion
    );
  };

  const SCREEN = () => (
    // #region [component]
    // ------------------------------
    <_STYLE>
      <TOP_SITE />

      <CONTENT />

      <FOOTER />
    </_STYLE>
    // ------------------------------
    // #endregion
  );

  // set COMPONENTS
  //_______________________________

  const MENU = () => {
    // #region [NOsetLogic]
    // ------------------------------

    // ------------------------------
    // #endregion

    // #region [allStyles]
    // ------------------------------

    // let stl01 = [useStyle];
    // let stl02 = [useStyle];

    // ------------------------------
    // #endregion

    return (
      // #region [component]
      // ------------------------------
      <>
        {sttMsg && (
          <Menu>
            <div className="title">
              <div>MENU</div>
              <TouchableOpacity onPress={toggleMsg}>
                <UseIcoMoon name="close" size={28} color="#fff" />
              </TouchableOpacity>
            </div>
            <div className="list">
              <a href="#top-site" onClick={toggleMsg}>
                {screenContent.txt02}
              </a>
              <a href="#about-cnv" onClick={toggleMsg}>
                {screenContent.txt03}
              </a>
              <a href="#benefits" onClick={toggleMsg}>
                {screenContent.txt04}
              </a>
              <a href="#footer" className="btn-download" onClick={toggleMsg}>
                {screenContent.txt06}
              </a>
            </div>
          </Menu>
        )}
      </>

      // ------------------------------
      // #endregion
    );
  };

  //_______________________________

  const TOP_SITE = props => {
    // #region [NOsetLogic]
    // ------------------------------

    // ------------------------------
    // #endregion

    // #region [NOallStyles]
    // ------------------------------

    // ------------------------------
    // #endregion

    return (
      // #region [component]
      // ------------------------------

      <TopSection>
        {/* navbar */}
        <div className="navbar">
          <div className="row">
            <img src={src01} className="logo" />
            <span className="txLogo">{screenContent.txt01}</span>
          </div>
          <div className="desk">
            <a href="#top-site">{screenContent.txt02}</a>
            <a href="#about-cnv">{screenContent.txt03}</a>
            <a href="#benefits">{screenContent.txt04}</a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sergirafa"
              target="_blank"
              className="btn-download"
            >
              {screenContent.txt06}
            </a>
          </div>
          <div className="mob">
            <TouchableOpacity onPress={toggleMsg}>
              <UseIcoMoon name="menu" size={28} color="#fff" />
            </TouchableOpacity>
          </div>
        </div>
        {/* banner */}
        <div className="banner" id="top-site">
          {/* textos */}
          <div className="flex1">
            <h1 className="titleBanner">{screenContent.txt07}</h1>
            <p>{screenContent.txt08}</p>
            <div className="links desk">
              <p>{screenContent.txt09}</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.sergirafa&hl=pt_BR"
                target="_blank"
              >
                <img src={src04} />
              </a>
              <p>
                Ou utilize a <u>versão Web</u> para iPhone, iPad e computadores.
              </p>
              <a href="https://app.sergirafa.com.br" target="_blank">
                <img src={src05} />
              </a>
            </div>
          </div>
          {/* imagens */}
          <div className="flex1 layer">
            <img src={src02} className="comp bg" />
            <img src={src03} className="comp cellphone" />
          </div>
        </div>
      </TopSection>

      // ------------------------------
      // #endregion
    );
  };

  //_______________________________

  const CONTENT = () => {
    // #region [NOsetLogic]
    // ------------------------------

    // ------------------------------
    // #endregion

    // #region [allStyles]
    // ------------------------------

    let stlTxt03 = [];
    let stlTxt04 = [rdMenuSelect && useStyle.primaryColor];

    // ------------------------------
    // #endregion

    return (
      // #region [component]
      // ------------------------------

      <>
        <Section>
          <div className="links mob">
            <p>{screenContent.txt09}</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.sergirafa&hl=pt_BR"
              target="_blank"
            >
              <img src={src04} />
            </a>
            <p>
              Ou utilize a <u>versão Web</u> para iPhone, iPad e computadores.
            </p>
            <a href="https://app.sergirafa.com.br" target="_blank">
              <img src={src05} />
            </a>
          </div>
        </Section>
        <Section className="title" id="about-cnv">
          <span>{screenContent.txt10}</span>
          <h2>{screenContent.txt10b}</h2>
        </Section>

        {/* ÍCONES */}
        <Section className="about">
          <div>
            <UseIcoMoon name="eye" size={52} color="#333" />
            <h4>{screenContent.txt11}</h4>
            <p>{screenContent.txt12}</p>
          </div>
          <div>
            <UseIcoMoon name="anger" size={52} color="#333" />
            <h4>{screenContent.txt13}</h4>
            <p>{screenContent.txt14}</p>
          </div>
          <div>
            <UseIcoMoon name="hand" size={52} color="#333" />
            <h4>{screenContent.txt15}</h4>
            <p>{screenContent.txt16}</p>
          </div>
          <div>
            <UseIcoMoon name="megaphone" size={52} color="#333" />
            <h4>{screenContent.txt17}</h4>
            <p>{screenContent.txt18}</p>
          </div>
        </Section>
        {/* BENEFÍCIOS */}
        <Section className="benefits" id="benefits">
          <div className="txt">
            <span>{screenContent.txt19}</span>
            <h2>{screenContent.txt20}</h2>
            <p>{screenContent.txt21}</p>
            <h4>{screenContent.txt22}</h4>
            <p>{screenContent.txt23}</p>
          </div>
          <img src={src08} className="bnf-img mob" />
        </Section>
        {/* IMG GIRAFA */}
        <Section className="download">
          <div className="txt">
            <span>{screenContent.txt24}</span>
            <h2>{screenContent.txt25}</h2>
            <div className="row-top">
              <div className="ico">
                <UseIcoMoon name="ok" size={18} color="#05a67d" />
              </div>
              <p>{screenContent.txt26}</p>
            </div>
            <div className="row-top">
              <div className="ico">
                <UseIcoMoon name="ok" size={18} color="#05a67d" />
              </div>
              <p>{screenContent.txt27}</p>
            </div>
            <div className="row-top">
              <div className="ico">
                <UseIcoMoon name="ok" size={18} color="#05a67d" />
              </div>
              <p>{screenContent.txt28}</p>
            </div>
            <div className="links">
              <p>{screenContent.txt29}</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.sergirafa&hl=pt_BR"
                target="_blank"
              >
                <img src={src04} />
              </a>
              <a href="https://app.sergirafa.com.br" target="_blank">
                <img src={src05} />
              </a>
            </div>
          </div>
        </Section>
      </>

      // ------------------------------
      // #endregion
    );
  };

  //_______________________________

  const FOOTER = () => {
    // #region [NOsetLogic]
    // ------------------------------

    // ------------------------------
    // #endregion

    // #region [allStyles]
    // ------------------------------

    let stlTxt05 = [];
    let stlTxt06 = [];

    // ------------------------------
    // #endregion

    return (
      // #region [component]
      // ------------------------------

      <Gradientfoot className="foot">
        <img src={src07} className="detail" />
        <div className="flexBetween" id="footer">
          {/* LOGO */}
          <div className="row flex1">
            <img src={src06} className="logo" />
            <span className="mgLog">{screenContent.txt01}</span>
          </div>
          {/* SOBRE PSICO. ELMIRO */}
          <div className="flex3">
            <span>
              <b>{screenContent.txt30}</b>
            </span>
            <p className="wdt">{screenContent.txt31}</p>
            <div>{screenContent.txt32}</div>
          </div>
          {/* REDES SOCIAIS */}
          <div className="scl-mtmob">
            <span className="tuk">{screenContent.txt33}</span>
            <div className="flexBetween mkp">
              <a href="https://www.facebook.com/ser.girafa" target="_blank">
                <UseIcoMoon name="facebook" size={22} color="#ccc" />
              </a>
              <a href="https://www.instagram.com/ser.girafa/" target="_blank">
                <UseIcoMoon name="instagram" size={22} color="#ccc" />
              </a>
              <a href="https://twitter.com/GirafaSer" target="_blank">
                <UseIcoMoon name="twitter" size={22} color="#ccc" />
              </a>
            </div>
          </div>
        </div>
        <a
          href="http://www.morfosweb.com.br"
          target="_blank"
          className="designBy flexEnd"
        >
          {screenContent.txt34} <img src={src09} className="design" />
        </a>
      </Gradientfoot>

      // ------------------------------
      // #endregion
    );
  };

  //_______________________________

  // ---------------------- THE END
  return <SCREEN />;
}
